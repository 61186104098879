$stacked-links-margin: 0;
$stacked-links-link-hover-color: color(text);
$stacked-links-link-active-color: color(secondary-active);
$stacked-links-link-logout-color: color(text);
$stacked-links-link-logout-icon-color: color(text);
$stacked-links-link-logout-icon-margin--large: rem-calc(0 20 0 0);
$stacked-links-link-font: #{rem-calc(14)} / 1.5 $font-primary;
$stacked-links-link-hover-font: #{rem-calc(14)} / 1.5 $font-primary;
$stacked-links-link-active-font: #{rem-calc(14)} / 1.5 $font-secondary;
$stacked-links-link-padding: rem-calc(16 0 16 20);
$stacked-links-link-padding--large: $stacked-links-link-padding;
$stacked-links-link-logout-font: #{rem-calc(14)} / 1.5 $font-primary;
$stacked-links-link-active-background: color(light);
$stacked-links-link-icon-width: rem-calc(10);
$stacked-links-link-icon-margin: rem-calc(0 25 0 0);
$stacked-links-link-logout-background: color(secondary);
$stacked-links-link-logout-hover-background: $stacked-links-link-logout-background;
$stacked-links-link-icon-color: color(text-secondary);
$stacked-links-link-logout-icon-color: color(text-secondary);

@import '@lora/05-components/stacked-links';