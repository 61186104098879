// Header
$product-hero-header-position-top: 8% !default;
$product-hero-header-position-top--large: null !default;
$product-hero-header-padding: rem-calc(0 15) !default;
$product-hero-header-padding--large: rem-calc(0 20) !default;
// Content
$product-hero-content-max-width: rem-calc(250) !default;
$product-hero-content-margin: 0 auto !default;
// Caption
$product-hero-caption-position-bottom: 8% !default;
$product-hero-caption-position-bottom--large: null !default;
$product-hero-caption-padding: rem-calc(0 15) !default;
$product-hero-caption-padding--large: rem-calc(0 20) !default;
// Rating
$product-hero-rating-icon: 'star-empty' !default;
$product-hero-rating-icon-usesvg: false !default;
$product-hero-rating-icon-active: 'star' !default;
$product-hero-rating-icon-active-usesvg: false !default;
$product-hero-rating-color: color(dark) !default;
$product-hero-rating-hover-color: $product-hero-rating-color !default;
// Dark mode
$product-hero-dark-mode-swatch-border-color: color(light) !default;
$product-hero-dark-mode-rating-color: color(light) !default;
$product-hero-dark-mode-rating-hover-color: $product-hero-dark-mode-rating-color !default;
$product-hero-dark-mode-rating-value-color: color(light) !default;

@mixin lora-components-product-hero {
    .c-product-hero {
        position: relative;
        text-align: center;

        .c-rating {
            &:is(a, button, label):hover {
                .c-rating__star {
                    @include svg-icon($product-hero-rating-icon, $product-hero-rating-hover-color, cover, left center, no-repeat, $usesvg: $product-hero-rating-icon-usesvg, $color-change: true);
                }

                .c-rating__star.m-active {
                    @include svg-icon($product-hero-rating-icon-active, $product-hero-rating-hover-color, cover, left center, no-repeat, $usesvg: $product-hero-rating-icon-active-usesvg, $color-change: true);
                }
            }
        }

        .c-rating__star {
            @include svg-icon($product-hero-rating-icon, $product-hero-rating-color, cover, left center, no-repeat, $usesvg: $product-hero-rating-icon-usesvg);
        }

        .c-rating__star.m-active {
            @include svg-icon($product-hero-rating-icon-active, $product-hero-rating-color, cover, left center, no-repeat, $usesvg: $product-hero-rating-icon-active-usesvg);
        }
    }

    .c-product-hero.m-dark {
        .c-swatch {
            border-color: $product-hero-dark-mode-swatch-border-color;
        }

        .c-rating {
            &:is(a, button, label):hover {
                .c-rating__star {
                    @include svg-icon($product-hero-rating-icon, $product-hero-dark-mode-rating-hover-color, cover, left center, no-repeat, $usesvg: $product-hero-rating-icon-usesvg, $color-change: true);
                }

                .c-rating__star.m-active {
                    @include svg-icon($product-hero-rating-icon-active, $product-hero-dark-mode-rating-hover-color, cover, left center, no-repeat, $usesvg: $product-hero-rating-icon-active-usesvg, $color-change: true);
                }
            }
        }

        .c-rating__star {
            @include svg-icon($product-hero-rating-icon, $product-hero-dark-mode-rating-color, cover, left center, no-repeat, $usesvg: $product-hero-rating-icon-usesvg, $color-change: true);
        }

        .c-rating__star.m-active {
            @include svg-icon($product-hero-rating-icon-active, $product-hero-dark-mode-rating-color, cover, left center, no-repeat, $usesvg: $product-hero-rating-icon-active-usesvg, $color-change: true);
        }

        .c-rating__value,
        .c-rating__review-value,
        .c-rating__review-value::before {
            color: $product-hero-dark-mode-rating-value-color;
        }
    }

    .c-product-hero__image {
        img {
            width: 100%;
        }
    }

    .c-product-hero__header {
        position: absolute;
        left: 0;
        width: 100%;
        top: $product-hero-header-position-top;
        padding: $product-hero-header-padding;

        @include breakpoint(large) {
            top: $product-hero-header-position-top--large;
            padding: $product-hero-header-padding--large;
        }
    }

    .c-product-hero__caption {
        position: absolute;
        left: 0;
        width: 100%;
        bottom: $product-hero-caption-position-bottom;
        padding: $product-hero-caption-padding;

        @include breakpoint(large) {
            bottom: $product-hero-caption-position-bottom--large;
            padding: $product-hero-caption-padding--large;
        }
    }

    .c-product-hero__content {
        max-width: $product-hero-content-max-width;
        margin: $product-hero-content-margin;
    }
}