@import "@lora/03-base/colors";

$palette-default: (
    light:     #fff, // White
    dark:      #000,// Black
    transparent: transparent,
);

$dark-mode-colors: (
    background:  #ecedef,
    text:        #000,
    border:      #b6b6bc,
);
$palette: map-extend($palette-default, $palette-custom);
$semantic-colors-custom: (
    primary:             #000,
    primary-active:      #3d3d3d,
    secondary:           #fff,
    secondary-active:    #312226,
    text:                color(dark),
    text-secondary:      #686868,
    success:             #46bf81,
    warning:             #ffae00,
    alert:               #b40a0a,
    info:                #312226,
    disabled:            #ccc,
    element-background:  #ecedef,
    global-background:   #f1e7ec,
    organism-background: #fcf0eb,
    border:              #b6b6bc,
    overlay:             rgba(color(dark), 0.5),
    highlight:           #b13e51
);

$semantic-colors: map-extend($semantic-colors-default, $semantic-colors-custom);