$product-detail-image-zoom-top: auto;
$product-detail-image-zoom-bottom: 0;
$product-detail-image-badge-top: 0;
$product-detail-image-image-carousel-pagination: none;
$product-detail-image-image-carousel-pagination--large: $product-detail-image-image-carousel-pagination;
$product-detail-image-mosaic-imagelink-height: rem-calc(280);

// 360 View
$product-detail-image-view360-right: rem-calc(17);
$product-detail-image-view360-top: rem-calc(65);

@import "@lora/05-components/product/product-detail-image";
