
                        $is-app_americas_ui-enabled: true;
                        $findation-content-hero-caption-z-index: 3 !default;
$findation-form-legend-color: color(dark) !default;
$findation-link-margin: rem-calc(10 0) !default;
$findation-link-margin--large: null !default;
$findation-iframe-max-height: rem-calc(76) !default;

@mixin uslayer-components-findation {
    .c-findation {
        .c-content-hero__caption {
            z-index: $findation-content-hero-caption-z-index;
        }

        .c-form__legend {
            color: $findation-form-legend-color;
        }

        .c-select__panel-list {
            max-height: 20.7vh;
        }
    }
}

@mixin uslayer-components-findationlinkwidget {
    .c-findationlinkwidget {
        margin: $findation-link-margin;

        &.m-block {
            iframe {
                min-width: 100%;
                max-height: $findation-iframe-max-height;
            }
        }

        @include breakpoint(large) {
            margin: $findation-link-margin--large;
        }
    }
}
                    